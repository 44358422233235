@import './fonts';

$steel_blue: #1E4C7D;
$black: #000000;
$white: #ffffff;

$primary_font: "Roboto Regular";
$primary_font_demi: "Roboto Bold";

$breakpoints: (
 "mobile-medium": 400px,
 "mobile-larger": 576px,
 "tablet": 768px,
 "tablet-large": 992px,
 "desktop-small": 1024px,
 "desktop-medium": 1440px,
 "desktop-large": 1600px,
 "desktop-extra-large": 1800px
);