@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/function';

.Journey {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 127px;

  .container-journey {
    align-items: center;
    display: flex;
    flex-direction: column;

    .logo-impulso {
      width: 80%;

      @include breakpoint(tablet) {
        max-width: 614px;
      }
    }

    h1 {
      color: $steel_blue;
      font-family: $primary_font_demi;
      font-size: pxToRem(23px);
      margin: 0;
      text-align: center;
      max-width: 412px;

      img {
        position: relative;
        top: 5px;
        width: 150px;
      }

      @include breakpoint(tablet) {
        font-size: pxToRem(48px);
        max-width: 1108px;

        img {
          top: 15px;
          width: 354px;
        }
      }
    }

    .realization {
      -webkit-transition: -webkit-transform .4s ease;
      margin-top: 40px;
      transition: transform .4s ease;

      a {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        text-decoration: none;
      }

      p {
        color: $black;
        font-family: $primary_font;
        font-size: pxToRem(15px);
        margin: 0;
      }

      img {
        height: 30px;
        margin-left: 10px;
        width: 57px;
      }

      &:hover {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }

      @include breakpoint(tablet) {
        margin-top: 20px;

        p {
          font-size: pxToRem(25px);
        }

        img {
          height: 40px;
          margin-left: 10px;
          width: 81px;
        }
      }
    }

    .apresentation-desktop {
      align-items: center;
      display: none;
      flex-direction: column;
      justify-content: center;
      margin: 30px 0;

      .button-apresentation {
        display: flex;
        justify-content: center;
        width: 100%;

        .ButtonContainer {
          display: flex;
          margin: 0;
  
          .Button {
            width: 626px;
          }
  
          .regulation {
            display: none;
          }
        }

        img {
          left: -20px;
          max-width: 177px;
          position: relative;
          top: 26px;
        }
        
        @include breakpoint(desktop-small) {
          margin: 30px 0 0 5%;
        }

        @include breakpoint(desktop-medium) {
          margin: 30px 0 0 15%;
        }
      }

      img {
        max-width: 961px;
        width: 80%;
      }

      @include breakpoint(desktop-small) {
        display: flex;
      }

      @include breakpoint(desktop-large) {
        img {
          width: 90%;
        }
      }
    }
  }

  .ButtonContainer {
    margin-top: 50px;

    .Button {
      font-size: pxToRem(25px);
      height: 80px;
      justify-content: center;
      width: 240px;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }
  
  .apresentation-mobile {
    max-width: 303px;
    width: 100%;

    @include breakpoint(desktop-small) {
      display: none;
    }
  }
}
