@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.ProjectModal {
  align-items: center;
  background: #0000003b 0 0 no-repeat padding-box;
  box-sizing: content-box;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  &.active {
    display: flex;

    @include breakpoint(desktop-small) {
      padding: 100px 0;
    }
  }

  .modal-status-preview {
    background-color: white;
    height: 90%;
    overflow-y: scroll;
    padding: 50px 40px;
    position: relative;
    width: 100%;

    @include breakpoint(desktop-small) {
      background-color: transparent;
      max-width: 60%;
      overflow-y: unset;
      padding: 0;
    }

    .close-modal {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: 15px;
      width: 100%;
      z-index: 10;

      @include breakpoint(desktop-small) {
        left: -20px;
        position: absolute;
        top: -60px;
      }

      .x-container {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 20px;
        margin: 0 0 40px 0 !important;
        position: relative;
        width: 30px;

        @include breakpoint(desktop-small) {
          margin: 0 !important;
        }

        .x-line {
          background-color: black;
          height: 2px;
          position: absolute;
          width: 100%;

          @include breakpoint(desktop-small) {
            background-color: white;
          }
        }

        .x-line-left {
          transform: rotate(45deg);
        }

        .x-line-right {
          transform: rotate(-45deg);
        }
      }
    }

    .box-input-create {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint(desktop-small) {
        height: 650px;
        flex-direction: row;
        padding-bottom: 50%;
      }

      .info-project {
        align-items: flex-start;
        background-color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;

        @include breakpoint(desktop-small) {
          height: 800px;
          padding: 100px 50px;
        }

        h3 {
          color: $steel_blue;
          font-family: $primary_font_demi;
          font-size: pxToRem(24px);
          margin: 0 0 24px 0;
        }

        img {
          height: 246px;
          max-width: 403px;
          width: 100%;
        }
        .container-info {
          margin-top: 16px;
          max-width: 403px;

          a {
            color: $steel_blue;
            text-decoration: unset;
          }

          p {
            color: $steel_blue;
            font-family: $primary_font;
            font-size: pxToRem(17px);
            margin: 5px 0;

            strong {
              font-weight: 700;
            }
          }
        }

        .open-project {
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 36px;
          border: 2px solid #1e4c7c;
          color: $steel_blue;
          cursor: pointer;
          font-family: $primary_font_demi;
          font-size: pxToRem(16px);
          height: 50px;
          margin-top: 28px;
          padding: 0 30px;
          width: 100%;
        }
      }

      .container-video {
        background-color: #1e4c7c;
        height: 100%;
        height: 800px;
        margin-top: 40px;
        min-width: 300px;
        width: 100%;

        @include breakpoint(desktop-small) {
          margin: 0;
        }

        .video {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;

          .container-button {
            display: flex;
            height: 100%;
            justify-content: flex-start;

            button {
              height: 50px;
            }
          }

          video {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }
}
