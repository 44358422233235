@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Creators {
  padding: 40px 25px;

  @include breakpoint(desktop-large) {
    padding: 160px;
  }

  .creators-img {
    margin-bottom: 20px;
    width: 100%;

    @include breakpoint(desktop-small) {
      margin-bottom: 100px;
      margin-left: 53px;
      max-width: 490px;
    }
  }

  .container-creators {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .separator-desktop {
      margin-top: 40px;
      width: 341px;

      @include breakpoint(desktop-small) {
        height: 600px;
        width: 78px;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      margin-right: 50px;
      max-width: 374px;

      @include breakpoint(tablet) {
        max-width: initial;
        width: 100%;
      }

      p {
        font-family: $primary_font;
        font-size: pxToRem(15px);
        margin: 35px 0 0 20px;
        width: 315px;

        @include breakpoint(tablet) {
          font-size: pxToRem(18px);
          width: 100%;
        }

        @include breakpoint(desktop-small) {
          font-size: pxToRem(17px);
          margin: 130px 0 0 0;
          width: 100%;
        }

        @include breakpoint(desktop-small) {
          font-size: pxToRem(19px);
        }
      }

      img {
        height: 100px;
        max-width: 287px;
      }

      h3 {
        color: $steel_blue;
        font-family: $primary_font_demi;
        font-size: pxToRem(27px);
        height: 50px;
        margin: 50px 0 40px 14px;
        max-width: 194px;

        @include breakpoint(tablet) {
          font-size: pxToRem(38px);
          height: 128px;
          margin-left: 20px;
          max-width: 254px;
        }

        @include breakpoint(desktop-small) {
          font-size: pxToRem(38px);
          height: 100px;
          margin: 0;
        }
      }

      &:last-child{
        @include breakpoint(desktop-small) {
          max-width: 610px;
        }
      }

      @include breakpoint(desktop-small) {
        max-width: 350px;
      }
    }

    @include breakpoint(desktop-small) {
      align-items: flex-start;
      flex-direction: row;
      margin-left: 15px;
    }

    @include breakpoint(desktop-large) {
      align-items: flex-start;
      justify-content: center;
    }
  }

  .ButtonContainer {
    margin: 60px 0;
  }
}
