@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.Timeline {
  display: flex;
  justify-content: center;
  margin-top: 90px;

  img {
    @include breakpoint(desktop-small) {
      max-width: 70%;
    }

    @include breakpoint(desktop-medium) {
      max-width: 63%;
    }
  }

  @include breakpoint(desktop-small) {
   justify-content: flex-start;
   margin: 140px 0 0 180px;
  }

  @include breakpoint(desktop-medium) {
    justify-content: flex-start;
    margin: 0 0 0 15%;
  }
}
