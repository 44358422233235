@mixin font-face($font-family, $font-weight, $font-style, $font-display, $local, $woff2-suffix, $woff-suffix) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF;
    src: local($local), url($woff2-suffix) format('woff2'), url($woff-suffix) format('woff');
  }
}

@include font-face("Roboto Regular",
  400,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Regular.woff2",
  "../fonts/Roboto-Regular.woff");

@include font-face("Roboto Bold",
  600,
  normal,
  swap,
  "Roboto",
  "../fonts/Roboto-Bold.woff2",
  "../fonts/Roboto-Bold.woff");