@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/function';

.BottomJourney {
  background-color: $white;
  padding: 0 30px;

  .container-bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 133px;
    justify-content: center;
    margin-top: 197px;
    width: 100%;

    .images-container {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      img {
        width: 100%;

        @include breakpoint(desktop-medium) {
          max-width: 650px;
        }

        @include breakpoint(desktop-large) {
          max-width: 970px;
        }
      }

      @include breakpoint(tablet) {
        width: 60%;
      }
    }

    p {
      color: $steel_blue;
      font-family: $primary_font;
      font-size: pxToRem(50px);
      position: relative;
      top: -130px;

      @include breakpoint(tablet) {
        font-size: pxToRem(70px);
        padding-left: 90px;
        position: inherit;
      }

      @include breakpoint(desktop-small) {
        font-size: pxToRem(80px);
        padding-left: 0;
      }

      @include breakpoint(desktop-medium) {
        font-size: pxToRem(103px);
        width: 576px;
      }
    }

    @include breakpoint(tablet) {
      flex-direction: row;
      height: auto;
      margin: 0;
    }
  }

  .infos-bottom-journey {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 100px;

    .boost-now {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0;

      p {
        color: $steel_blue;
        font-family: $primary_font_demi;
        font-size: pxToRem(28px);
        margin: 0 0 50px 0;
        text-align: center;
        width: 440px;
      }

      .ButtonContainer {
        align-items: flex-start;
        margin: 0;
      }

      @include breakpoint(desktop-small) {
        align-items: flex-start;

        p {
          font-size: pxToRem(22px);
          text-align: left;
          width: 100%;
        }
      }

      .ButtonContainer {
        align-items: center;
        display: flex;

        .Button {
          font-size: pxToRem(30px);
          justify-content: center;
          width: 240px;
        }

        @include breakpoint(desktop-small) {
          align-items: flex-start;

          .Button {
            width: 100%;
          }
        }
      }
    }

    p {
      color: $black;
      font-family: $primary_font;
      font-size: pxToRem(23px);
      margin-top: 50px;
      max-width: 318px;
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  .journey-container {
    width: 90%;

    @include breakpoint(desktop-medium) {
      margin-top: 115px;
    }
  }

  .course-trails {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;

    p {
      color: $steel_blue;
      font-family: $primary_font_demi;
      font-size: pxToRem(25px);
      margin: 100px 0 80px 0;
      text-align: center;
      width: 248px;

      @include breakpoint(desktop-small) {
        color: $black;
        font-family: $primary_font;
        font-size: pxToRem(31px);
        margin: 100px 0 0 0;
        text-align: inherit;
        width: 315px;
      }
    }


    @include breakpoint(desktop-small) {
      flex-direction: row;
      align-items: flex-start;
    }

    @include breakpoint(desktop-medium) {
      margin: 40px 0 0 60px;
    }

    @include breakpoint(desktop-large) {
      margin: 40px 0 0 120px;
    }
  }

  @include breakpoint(desktop-medium) {
    display: flex;
    flex-direction: column;
    width: 75%;
  }
}