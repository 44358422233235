@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.CardProject {
  background: #fefefe;
  box-shadow: 0px 6px 25px #00000014;
  box-sizing: border-box;
  height: max-content;
  padding: 23px 31px;
  width: 389px;
  
  @include breakpoint(desktop-small) {
    height: 565px;
  }

  @include breakpoint(desktop-large) {
    width: 419px;
  }

  img {
    height: 246px;
    width: 100%;
  }

  .container-info {
    margin-top: 16px;
    height: 170px;

    @include breakpoint(desktop-small) {
      height: 178px;
    }

    h3 {
      color: $steel_blue;
      font-family: $primary_font_demi;
      font-size: pxToRem(24px);
      margin: 0 0 8px 0;
    }

    p {
      color: $steel_blue;
      font-family: $primary_font;
      font-size: pxToRem(14px);
      margin: 5px 0;

      strong {
        font-weight: 700;
      }

      @include breakpoint(desktop-small) {
        font-size: pxToRem(17px);
      }
    }
  }

  .open-modal {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: 2px solid #1e4c7c;
    color: $steel_blue;
    cursor: pointer;
    font-family: $primary_font_demi;
    font-size: pxToRem(19px);
    height: 50px;
    margin-top: 28px;
    width: 166px;
  }
}
