@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/function';

.KnowTheProgram {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  width: 100%;

  img {
    margin: 20px;
    width: 80%;

    @include breakpoint(tablet) {
      max-width: 664px;
    }
  }

  .program {
    @include breakpoint(tablet) {
      max-width: 570px;
    }
  }

  .arrow {
    width: 33px;

    @include breakpoint(tablet) {
      width: 64px;
    }
  }

  .description-program {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    p {
      font-family: $primary_font;
      font-size: pxToRem(15px);
      max-width: 299px;
    }

    h3 {
      color: $steel_blue;
      display: flex;
      font-family: $primary_font_demi;
      font-size: pxToRem(29px);
      text-align: left;
      width: 75%;
    }

    @include breakpoint(tablet) {
      flex-direction: row;
      align-items: flex-start;

      p {
        font-size: pxToRem(19px);
        margin: 0 30px 0 70px;
        max-width: 540px;
      }

      h3 {
        display: none;
      }
    }
  }

  .container-know {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 80%;

    div {
      display: flex;
      flex-direction: column;
      margin: 30px;
      width: 282px;

      img {
        height: 111px;
        max-width: 315px;
      }

      p {
        color: $black;
        font-family: $primary_font;
        font-size: pxToRem(20px);
        text-align: center;
      }
    }

    @include breakpoint(desktop-small) {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 65%;
    }
  }

  .ButtonContainer {
    display: flex;
    margin-top: 50px;

    .Button {
      font-size: pxToRem(15px);
      justify-content: center;
      width: 100%;
    }

    @include breakpoint(desktop-small) {
      .Button {
        font-size: pxToRem(18px);
        height: 95px;
      }
    }
  }
}
