@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/function.scss";
@import "../../../assets/scss/mixins.scss";
@import "../../../assets/scss/fonts.scss";

.SlideCards {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container-slide {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      color: $steel_blue;
      font-family: $primary_font_demi;
      font-size: pxToRem(23px);
      max-width: 350px;
      text-align: center;

      @include breakpoint(tablet) {
        font-size: pxToRem(48px);
        max-width: 642px;
        margin-left: 2%;
      }

      @include breakpoint(desktop-medium) {
        text-align: start;
      }

      @include breakpoint(desktop-large) {
        font-size: pxToRem(60px);
        margin: 0;
        max-width: 932px;
      }
    }

    .box-slide-desktop {
      align-items: flex-start;
      display: none;
      flex-direction: column;

      p {
        color: $steel_blue;
        font-family: $primary_font_demi;
        font-size: pxToRem(20px);
        max-width: 340px;
      }

      .ButtonContainer {
        align-items: flex-start;
      }

      @include breakpoint(desktop-small) {
        p {
          font-size: pxToRem(20px);
        }
      }

      @include breakpoint(desktop-medium) {
        display: flex;

        p {
          font-size: pxToRem(35px);
        }
      }
    }

    @include breakpoint(desktop-small) {
      justify-content: space-around;
      margin-top: 55px;
      width: 100%;
    }

    @include breakpoint(desktop-large) {
      gap: unset;
      justify-content: space-between;
      margin: 170px 127px 0 185px;
      width: 79%;
    }
  }

  .bottom-cards {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      width: 222px;

      img {
        width: 100%;
      }

      p {
        color: $steel_blue;
        font-family: $primary_font_demi;
        font-size: pxToRem(17px);
        text-align: center;
      }

      @include breakpoint(desktop-small) {
        width: 263px;

        p {
        font-size: pxToRem(22px);
        text-align: start;
        }

        img {
          max-height: 250px;
        }
      }

      
      @include breakpoint(desktop-large) {
        width: 352px;

        p {
        font-size: pxToRem(31px);
        text-align: start;
        }

        &:last-child {
          width: 378px;
        }

        img {
          max-height: 355px;
        }
      }
    }

    @include breakpoint(desktop-small) {
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
    }

    @include breakpoint(desktop-medium) {
      width: 75%;
    }
  }
}