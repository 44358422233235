@import "../../../assets/scss/variables";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.Projects {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 66px;
  margin-top: 143px;
  padding: 20px;

  &.active {
    overflow-y: unset;
  }

  .container-title {
    padding: 20px;

    h4 {
      color: $steel_blue;
      font-family: $primary_font_demi;
      font-size: pxToRem(24px);
      margin: 0 0 8px 0;
    }

    @include breakpoint(desktop-medium) {
      padding: 0 0 0 60px;
      width: 80%;
    }

    @include breakpoint(desktop-large) {
      padding: 0 0 0 100px;
      gap: 66px;
    }

    @include breakpoint(desktop-extra-large) {
      padding: 0 0 0 200px;
    }
  }

  .container-cards {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(desktop-small) {
      gap: 20px;
    }

    @include breakpoint(desktop-medium) {
      padding: 0 0 100px 60px;
    }

    @include breakpoint(desktop-large) {
      padding: 0 0 100px 100px;
      gap: 50px;
    }

    @include breakpoint(desktop-extra-large) {
      padding: 0 0 100px 200px;
    }
  }
}
