@import "../../../assets/scss/variables";
@import "../../../assets/scss/fonts";
@import "../../../assets/scss/function";
@import "../../../assets/scss/mixins";

.Footer {
  align-items: center;
  background-color: $steel_blue;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: max-content;
  position: relative;
  width: 100%;
  z-index: 2;

  .logo-somas {
    width: 180px;
  }

  @include breakpoint(desktop-small) {
    padding: 50px;
  }

  h1 {
    color: $white;
    font-family: $primary_font;
    font-size: pxToRem(80px);
    font-weight: lighter;
    margin: 20px 0;
  }

  div {
    align-items: center;
    border-top: 1px solid $white;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    width: 100%;

    @include breakpoint(desktop-small) {
      flex-direction: row;
      justify-content: space-between;
      width: 600px;
    }

    svg {
      height: 38px;
      width: 34px;
    }

    p,
    a {
      color: $white;
      font-family: $primary_font;
      font-size: pxToRem(12px);
      line-height: 20px;
      margin: 0;
      text-align: center;
      text-decoration: none;

      @include breakpoint(desktop-small) {
        margin: 0 0 0 20px;
      }
    }

    p {
      @include breakpoint(desktop-small) {
        max-width: 100px;
        text-align: left;
      }
    }

    &:first-child {
      div {
        @include breakpoint(desktop-small) {
          height: 20px;
        }
      }
    }

    div {
      border: none;
      margin: 10px 0;
      padding: unset;
      width: unset;

      div {
        @include breakpoint(desktop-small) {
          flex-direction: column;
        }
      }
    }


    .whats-container {
      position: relative;
    }

    .whatsapp {
      left: 127px;
      max-width: 227px;
      position: absolute;
      top: -38px;
    }
  }
}
