@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.ButtonContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .Button {
    align-items: center;
    background-color: $white;
    border-radius: 54px;
    border: 2px solid $steel_blue;
    color: $steel_blue;
    cursor: pointer;
    display: inline-flex;
    font-family: $primary_font_demi;
    font-size: pxToRem(15px);
    height: 76px;
    padding: 10px 22px;

    @include breakpoint(tablet) {
      font-size: pxToRem(34px);
      height: 108px;
    }
  
    @include breakpoint(desktop-large) {
      padding: 30px 66px;
    }
  
    &:hover {
      background-color: $steel_blue;
      color: $white;
    }

    &.active {
      background-color: $steel_blue;
      color: $white;
    }
  }

  .regulation {
    border-bottom: 1px solid #1E4C7D;
    color: $steel_blue;
    font-family: $primary_font;
    font-size: pxToRem(14px);
    height: 30px;
    margin-top: 18px;
    text-decoration: none;

    @include breakpoint(tablet) {
      font-size: pxToRem(18px);
      height: 30px;
      margin-top: 30px;
    }
    
    @include breakpoint(desktop-small) {
      font-family: $primary_font;
      font-size: pxToRem(19px);
      text-transform: unset;
    }
  }
}
