@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Navbar {
  align-items: center;
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 24px;
  place-content: center space-between;
  position: fixed;
  top: -5px;
  transition: .5s;
  width: 100vw;
  z-index: 4;

  img {
    width: 192px;
    height: 85px;
  }

  @include breakpoint(tablet) {
    padding: 0 30px;
  }

  @include breakpoint(desktop-small) {
    padding: 0 70px;
  }

  @include breakpoint(desktop-large) {
    padding: 0 85px;
  }

  &>a {
    position: relative;
    text-decoration: none;
    z-index: 3;

    h1 {
      color: $steel_blue;
      font-family: $primary_font;
      font-size: 31px;
      margin: 0;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  div {
    align-items: flex-start;
    animation: closeMenu 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100vh;
    justify-content: flex-start;
    padding: 150px 20px 30px;
    position: absolute;
    top: 0;
    width: 100vw;

    @include breakpoint(desktop-small) {
      animation: unset;
      background-color: transparent;
      flex-direction: row;
      gap: 50px;
      height: unset;
      justify-content: flex-start;
      padding: unset;
      position: unset;
      width: 70vw;
    }
  }

  button {
    align-items: flex-end;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 27px;
    justify-content: space-around;
    margin-right: 24px;
    padding: 0;
    width: 40px;
    z-index: 2;

    @include breakpoint(desktop-small) {
      display: none;
      visibility: hidden;
    }

    span {
      background-color: $steel_blue;
      display: block;
      height: 3px;
      transition: .6s;
      -webkit-transform-origin: left center;
      transform-origin: left center;
      width: 30px;
      z-index: 2;
    }
  }

  &.inverted {
    filter: invert(1);
  }

  &.back {
    transition: 0;
    z-index: 1;
  }

  &.menu-active {
    div {
      animation: openMenu 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

      @include breakpoint(desktop-small) {
        animation: unset;
      }
    }

    button>span {
      &:nth-child(1) {
        margin-bottom: 2px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        margin-bottom: -2px;
        transform: rotate(-45deg);
      }
    }
  }

  &.menu-closed {
    div {
      animation: closeMenu 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

      @include breakpoint(desktop-small) {
        animation: unset;
      }
    }
  }

  @keyframes openMenu {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(-6%);
    }
  }

  @keyframes closeMenu {
    0% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(-110%);
    }
  }
}