@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/function';

.NavigationLink {
  color: $steel_blue;
  font-family: $primary_font;
  font-size: pxToRem(20px);
  text-decoration: none;
  text-transform: uppercase;
  
  @include breakpoint(desktop-small) {
    font-family: $primary_font;
    font-size: pxToRem(17px);
    text-transform: unset;
  }

  &.active, &:hover {
    box-shadow: 0 4px 0 -2px $steel_blue;
    font-family: $primary_font_demi;
  }
}
